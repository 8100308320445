.header {
  background-color: $white;
  padding: 15px 0;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.06);
  position: sticky;
  top: 0;
  z-index: 5;

  &__elements {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  &__right,
  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }


  &__center {
    width: 100%;
  }

  &__logo {
    width: 150px;
  }

  &__search {
    width: 100%;
    max-width: 500px;
    position: relative;
    z-index: 5;

    .ant-input-affix-wrapper {
      height: 35px;
      border-radius: 999px;
    }

    input {
      position: relative;
      top: 3px;
      height: 28px;
    }

    .ant-input-prefix,
    svg {
      position: relative;
      top: 2px;
      fill: transparent;
      stroke: $black;
      width: 25px;
      height: 25px;
    }
  }

  &__search-result {
    position: absolute;
    min-height: 400px;
    width: 100%;
    left: 0;
    top: 40px;
    border-radius: 20px;
    background-color: $white;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
    z-index: 99;
    @include standardTransition;
    pointer-events: none;
    opacity: 0;


    &.active {
      @include standardTransition;
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__navigation-list {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__navigation-button {
    @include standardTransition;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1.25rem;
    line-height: 1.875rem;
    &.active{
      color: $radar;
      pointer-events: none;
      svg{
        stroke: $radar;

      }
    }
    &:hover {
      @include standardTransition;
      color: $radar;

      svg {
        stroke: $radar;
      }
    }
  }

  &__navigation-icon {
    fill: transparent;
    stroke: $black;
    width: 25px;
    height: 25px;
    @include standardTransition;

  }

  &__auth {
    min-width: 100px;
    display: flex;
    align-items: center;
    gap: 10px;


    svg {
      width: 25px;
      height: 25px;

    }
  }

  .ant-btn.ant-btn-primary,
  .ant-btn.ant-btn-default {
    height: 35px;
  }

  &__auth-user {
    font-size: 1.25rem;
    line-height: 1.875rem;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__language {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  &__language-name {
    display: flex;
    align-items: center;
    gap: 5px;


  }


  &__menu {
    display: none;
    position: relative;


  }

  &__menu-button {
    height: 25px;

    svg {
      width: 40px;
      height: 40px;
      stroke: $radar;
    }
  }


  &__menu-bar {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    @include standardTransition;

    &.active {
      opacity: 1;
      pointer-events: initial;
      @include standardTransition;
    }
  }


  &__drawer-close {
    width: 45px !important;
    height: 45px !important;

    svg {
      width: 45px;
      height: 45px;
      stroke: $radar;
      @include standardTransition;

    }

    &:hover {
      svg {
        @include standardTransition;
        opacity: 0.6;
      }
    }
  }

  &__drawer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .header__navigation {
      margin-top: 20px;
    }

    .header__navigation-list {
      flex-direction: column;
      align-items: flex-start;
    }

    .header__auth {
      margin-bottom: 10px;
    }

    .ant-btn.ant-btn-primary,
    .ant-btn.ant-btn-default {
      width: 100%;
    }

    .header__search {
      max-width: none;
    }
  }

  &__drawer-social {
    margin-top: auto;

    .button__social {
      justify-content: center;
    }
  }

  &__drawer-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }

  &__drawer-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__drawer-top--functional {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: absolute;
    bottom: 50px;
    width: 100%;
  }
}

.header__language-select {

  width: 85px;

  span, input {
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-family: 'Ubuntu', sans-serif;
    color: $dark !important;
  }

  svg {
    width: 25px;
    height: 25px;
  }
}

.auth {
  position: absolute;
}