body.dark {
  @include standardTransition;
  color: $white;
  background-color: $darkBackground;

  .ant-layout-content {
    background-color: $darkBackground;

  }

  svg {
    fill: transparent;
    stroke: $white;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    color: $radar !important;
  }

  .ant-select-item.ant-select-item-option:hover {

    opacity: 0.5;
  }

  .ant-menu {
    background: $darkBackground;
  }

  .ant-drawer-content,
  .ant-modal-close-x:hover,
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu-title:active,
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active,
  .auth__already,
  input,
  .ant-modal-title,
  .ant-modal-header,
  .ant-modal-content,
  .ant-select-item.ant-select-item-option,
  .ant-select-dropdown,
  .header__right .ant-select .ant-select-selector .ant-select-selection-item,
  .ant-select .ant-select-selector,
  .ant-menu,
  .default__content,
  .footer,
  .header {
    @include standardTransition;
    background-color: $darkBackground !important;
    color: $white !important;

  }

  label,
  .header__search-result,
  .cv__edit-form,
  .ant-select-item.ant-select-item-option,
  .header__right .ant-select .ant-select-selector .ant-select-selection-item,
  .ant-select .ant-select-selector,
  .ant-menu.ant-menu-sub.ant-menu-inline,
  .ant-menu-item,
  .ant-select-item.ant-select-item-option,
  .ant-select-dropdown,
  .ant-form-item-control-input textarea,
  .ant-select,
  .ant-picker,
  span.ant-input-affix-wrapper,
  input.ant-input,
  input,
  .cv__list-image,
  .home__slogan-right {
    @include standardTransition;
    color: $white !important;
    background-color: $darkElement !important;
  }

  .ant-menu-item {

    svg.ant-menu-item-icon, span {
      @include standardTransition;
    }

    &:hover {
      span {
        @include standardTransition;
        color: $radar;
      }

      svg {
        @include standardTransition;
        stroke: $radar;
      }
    }
  }

  .ant-menu-submenu-title {
    svg.ant-menu-item-icon {
      @include standardTransition;

    }

    .ant-menu-submenu-arrow {
      &::before, &::after {
        @include standardTransition;

      }
    }

    &:hover {
      .ant-menu-title-content {
        color: $radar;
        @include standardTransition;

      }

      svg {
        @include standardTransition;
        stroke: $radar;
      }

      .ant-menu-submenu-arrow {
        &::before, &::after {
          color: $radar;
          @include standardTransition;

        }
      }
    }

  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus,
  input:-webkit-autofill::selection {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: $white;
    color: $white;

  }

  input::selection,
  input::-moz-selection {
    color: $white;
    background-color: $white;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill,
  input[type="text"]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $darkElement inset !important;
    box-shadow: 0 0 0 30px $darkElement inset !important;
    color: $white !important;


    &:hover {
      -webkit-box-shadow: 0 0 0 30px $darkElement inset !important;
      box-shadow: 0 0 0 30px $darkElement inset !important;
      color: $white !important;

    }

    &:focus {
      -webkit-box-shadow: 0 0 0 30px $darkElement inset !important;
      box-shadow: 0 0 0 30px $darkElement inset !important;
      background-color: transparent !important;
      color: $white !important;

    }

    &:active {
      -webkit-box-shadow: 0 0 0 30px $darkElement inset !important;
      box-shadow: 0 0 0 30px $darkElement inset !important;
      color: $white !important;

    }

    &:target {
      -webkit-box-shadow: 0 0 0 30px $darkElement inset !important;
      box-shadow: 0 0 0 30px $darkElement inset !important;
      color: $white !important;

    }
  }

  .ant-select-selection-item,
  .ant-empty-description,
  .ant-page-header-heading-title,
  h1, h2, h3, h4, h5, h6 {
    color: $white !important;

  }

  .ant-notification-notice {
    background-color: $darkElement !important;

    .ant-notification-notice-message {
      color: $white !important;
    }
  }

  .ant-notification-notice-close,
  .ant-notification-close-x,
  .anticon.anticon-close.ant-notification-close-icon {
    &:hover {
      background-color: $darkElement !important;
    }
  }

  .ant-input-data-count {
    color: $white;
  }


  input::placeholder {
    color: $white !important;
  }

  .ant-form-item-control-input-content span {
    color: $white;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: $white !important;
  }

  .magazine__history-tooltip {
    background-color: $darkElement;
  }

  .magazine .fullscreen-enabled {
    background-color: $darkBackground;

  }

  .ant-menu-submenu.ant-menu-submenu-inline {
    @include standardTransition;

  }
}

.ant-select-open,
.ant-picker.ant-picker-focused,
span.ant-input-affix-wrapper-focused {
  border-color: $radar !important;

  &:hover {
    border-color: $radar !important;
  }

  svg {
    stroke: $radar;
    @include standardTransition;
  }
}