.home {
  padding: 50px 0;
  position: relative;

  &__content {
    display: flex;
  }

  &__slogan {
    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 50px;
    }

    &-text,
    &-text span {
      font-size: 3.5rem;
      line-height: 4.5rem;
      max-width: 600px;
    }

    &-text {
      margin-bottom: 20px;
    }

    &-text span {
      margin-left: 10px;
    }

    &-right {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      width: 700px;
      height: 400px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__profile {
    @include box;
    position: relative;
    width: 200px;
    min-height: 200px;
    height: fit-content;
  }

  &__profile-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-radius: 10px 10px 0 0;
    background: $radar;
  }

  &__profile-img {
    @include boxShadow;
    position: relative;
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    top: 10px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 50px;
      height: 50px;
    }
  }

  &__profile-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  &__profile-name {
    text-align: center;
    margin-bottom: 10px;
  }

  &__profile-ocupation {
    margin-bottom: 20px;
  }

  &__create {
    @include box;

    max-width: 900px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__create-icons {
    display: flex;
    gap: 5px;
  }

  &__ads {
    @include box;
    width: 200px;
    height: fit-content;
  }

  &__posts {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 50px;
  }

  &__post {
    @include box;
  }

  &__post-top {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__post-user {
    display: flex;
    align-items: center;
    gap: 10px;


  }

  &__post-profile--icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid $radar;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      stroke: $radar;
      width: 30px;
      height: 30px;
    }
  }

  &__post-comment {
    margin-bottom: 20px;
  }

  &__post-counts {
    display: flex;
    align-items: center;
  }

  &__post-count {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__post-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__post-button {
    @include standardTransition;
    display: flex;
    align-items: center;
    gap: 5px;
    color: $black;

    svg {
      @include standardTransition;
    }

    &:hover {
      @include standardTransition;
      color: $radar;

      svg {
        @include standardTransition;
        stroke: $radar;
      }
    }
  }

  &__post-comment {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
}
