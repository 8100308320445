.auth {
  &__buttons {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__item {
    margin-bottom: 20px;
  }

  &__already {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0 20px 0;
  }


  &__already-link {
    cursor: pointer;
    padding: 0 !important;
  }

  &__forgot-link {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 !important;
    margin-top: -10px;
  }

  &__confirm-text {
    margin-bottom: 20px;
  }

  &__confirm-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: nowrap;

    input {
      width: 100%;
      height: 70px;
      font-size: 1.75rem;
      line-height: 2.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 10px;
      border: 2px solid $greyLight !important;

      &:focus-visible {
        border: 2px solid $radar !important;
      }
    }
  }
}

.ant-btn.ant-btn-primary.auth__button-facebook {
  background-color: $facebookColor;
  gap: 10px;

  svg {
    width: 25px;
    height: 25px;
    stroke: $white;
    @include standardTransition;

  }

  &:hover {
    border-color: $facebookColor;
    background-color: transparent;

    svg {
      stroke: $facebookColor;
      @include standardTransition;

    }

    span {
      color: $facebookColor;

    }
  }


}

.ant-btn.ant-btn-primary.auth__button-google {
  background-color: $googleColor;
  gap: 10px;

  svg {
    width: 25px;
    height: 25px;
    stroke: $white;
    @include standardTransition;

  }

  &:hover {
    border-color: $googleColor;
    background-color: transparent;

    svg {
      stroke: $googleColor;
      @include standardTransition;

    }

    span {
      color: $googleColor;

    }
  }


}


.ant-modal-header .ant-modal-title {
  text-align: center;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 2.625rem;
}