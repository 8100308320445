.magazine {
  margin: 40px 0;
  overflow: hidden;

  .fullscreen-enabled {
    background-color: $greyLight;
    .magazine__functional-btns {
      top: 20px;
      right: 20px;
      position: absolute;
      z-index: 9;
      padding: 10px 20px 20px 20px;
      background-color: rgba(0, 0, 0, .6);
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      @include standardTransition;
    }

    .magazine__book{
      position: relative;
      top: 40px;
    }

    .magazine__history-tooltip {
      left: -150px;
    }
  }

  &__history {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__book-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    &.maximize {
      .magazine__functional-btns {
        svg {
          stroke: $white;
        }
      }
    }
  }

  &__overlay {
    @include standardTransition;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    pointer-events: none;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5;
    left: 0;
    top: 0;

    &.active {
      @include standardTransition;
      opacity: 1;
    }
  }

  &__overlay-content {

    //&.active {
    //  position: fixed;
    //  z-index: 6;
    //  top: 50%;
    //  left: 36%;
    //  transform: translate(-50%, -50%);
    //}
  }

  &__interactive,
  &__book {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__counter {
    margin-top: 10px;
    margin-bottom: 20px;

    input {
      width: 60px;
    }
  }

  &__modal-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
  }

  &__modal-cover {
    h5 {
      margin-bottom: 10px;
    }

  }

  &__download-pdf {
    margin-bottom: 20px;
  }

  &__table-tags {
    display: flex;
    flex-direction: column;
  }

  &__book-flip {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.10);
  }

  &__functional-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    width: 100%;

    button {
      width: 40px !important;
      height: 40px;

    }

    svg {
      width: 40px;
      height: 40px;
      @include standardTransition;

      &:hover {
        @include standardTransition;
        stroke: $radar !important;
      }
    }
  }

  &__functional {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    @include standardTransition;

    svg {
      @include standardTransition;
      stroke: $white;
    }
  }

  &__view-btn {
    display: flex;
    align-items: center;
    gap: 5px;

    &.active {
      color: $radar;
      @include standardTransition;

      svg {
        @include standardTransition;
        stroke: $radar;
      }
    }
  }

  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 400px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &:hover {
      .magazine__preview-overlay {
        @include standardTransition;
        opacity: 1;
      }
    }
  }

  &__preview-overlay {
    @include standardTransition;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &__preview-overlay--btn {
    width: 50px;
    height: 50px;

    svg {
      @include standardTransition;
      width: 50px;
      height: 50px;
      stroke: $white;

      &:hover {
        @include standardTransition;
        stroke: $radar;
      }
    }
  }

  &__preview-btn {
    width: 300px;
    height: 400px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;
  }

  &__modal-history--items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 200px;
    overflow: auto;
    margin-top: 20px;
    padding-right: 5px;
    width: 100%;

    &.nav {
      max-height: 400px;
      margin-top: 0;
    }
  }

  &__modal-history--item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    padding-right: 40px;
    border: 1px solid $radar;
    border-radius: 20px;
    justify-content: space-between;
    min-width: 100%;
  }

  &__modal-history--delete {
    position: absolute;
    right: 5px;
    top: 3px;
    width: 20px;
    height: 20px;

    &:hover {
      svg {
        @include standardTransition;
        stroke: $red;
      }
    }

    svg {
      @include standardTransition;
      width: 20px;
      height: 20px;
    }
  }


  &__counter-elem {
    display: flex;
    align-items: center;
    gap: 10px;

    .ant-form-item {
      margin-bottom: 0 !important;
    }

    div {
      display: flex;
      align-items: center;
    }
  }

  &__history {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: relative;
  }

  &__history-btn {
    position: relative;
    padding: 0 !important;
  }

  &__history-tooltip {
    @include standardTransition;
    opacity: 0;
    pointer-events: none;
    background-color: $white;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    position: absolute;
    left: -70px;
    top: 60px;
    width: 250px;
    padding: 20px;

    &.active {
      @include standardTransition;
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__content-bottom {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.stf__item.--left {
  box-shadow: 10px -1px 25px 5px rgba(0, 0, 0, 0.3);
}

.stf__item.--right {
  box-shadow: -10px -1px 25px 5px rgba(0, 0, 0, 0.3);
}

.magazine__book-flip {

  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 48%;
    transform: translateX(-50%);
    width: 40px;
    height: 100%;
    background: linear-gradient(to left, rgba(70, 70, 70, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 9;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 52%;
    transform: translateX(-50%);
    width: 40px;
    height: 100%;
    background: linear-gradient(to right, rgba(70, 70, 70, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 9;
  }

  &.maximize {
    &:after {
      left: calc(49% - 3px);
    }

    &:before {
      left: calc(51% + 5px);

    }
  }
}