.forbidden {
  color: $radar;
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fb {
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fb h1 {
  font-size: 100px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px $radar;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
