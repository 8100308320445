.footer {
  background-color: $white;
  min-height: 300px;
  box-shadow: 0px -6px 15px 5px rgba(0, 0, 0, 0.06);
  padding: 20px 0;
  z-index: 2;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    height: 250px;
  }

  &__content-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  &__info {
    display: inline-flex;
    flex-direction: column;
    gap: 15px;
  }

  &__info-link {
    font-size: 1.125rem;
    line-height: 1.688rem;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      @include standardTransition;
    }

    &:hover {
      svg {
        @include standardTransition;
        stroke: $radar;
      }
    }
  }

  &__links {
    display: flex;
    gap: 80px;
  }

  &__links-title {
    color: $radar;
    margin-bottom: 10px;
  }

  &__links-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  &__links-button {
    @include standardTransition;

    &:hover {
      @include standardTransition;
      color: $radar;
    }
  }

  &__copyright {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  &__copyright-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
}