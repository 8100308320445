.avatar {

  &__clear {
    opacity: 1;
    pointer-events: none;
    transition: all 0.3s linear;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    top: -200px;
    left: 0;
    border-radius: 50%;
    z-index: 9;
  }
}

.settings {
  &__uploader {
    position: relative;
    border: 1px solid $radar;
    overflow: hidden;

    .ant-image-img {
      width: 100%;
      height: 100%;
    }

    .ant-image {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &__image-upload {
    position: absolute;
    left: 0;
    top: 0;
    @include standardTransition;
    background-color: transparent;


    &:hover {
      @include standardTransition;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__image-delete {
    svg {
      stroke: $white;
    }
  }

  &__image-overlay {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    @include standardTransition;

    &:hover {
      @include standardTransition;
      opacity: 1;
    }
  }

  &__image-delete {
    svg {
      @include standardTransition;
      width: 40px;
      height: 40px;
    }

    &:hover {
      svg {
        @include standardTransition;
        stroke: $radar;
      }
    }
  }
}
