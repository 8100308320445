.cv {
  padding: 100px 0;

  &__edit {
    padding: 100px 0;

  }

  &__list {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
  }

  &__list-image {
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include standardTransition;
    border-radius: 10px 10px 0 0;

    .ant-btn-icon-only {
      opacity: 0;
      width: 50px;
      height: 50px;
    }

    svg {
      width: 50px;
      height: 50px;
    }

    &:hover {
      @include standardTransition;
      background-color: $greyLight;

      .ant-btn-icon-only {
        opacity: 1;
      }
    }
  }

  &__list-item {
    height: 350px;
    width: 300px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    position: relative;
  }

  &__list-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $radar;
    border-radius: 0 0 10px 10px;
    color: $white;

  }

  &__user-action {
    .ant-btn.ant-btn-circle.ant-btn-link.ant-btn-icon-only {
      @include standardTransition;

      svg {
        @include standardTransition;
        stroke: $white !important;
      }

      &:hover {
        svg {
          @include standardTransition;
          opacity: 0.6;
        }
      }
    }
  }

  &__edit-form {
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
  }

  &__additional {
    padding: 20px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid $greyLight;
  }

  &__settings-button,
  &__add-button {
    svg {
      @include standardTransition;
    }

    &:hover {
      svg {
        @include standardTransition;

        stroke: $radar;
      }
    }
  }

  &__additional-items--list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__additional-list,
  &__additional {
    width: 100%;
    cursor: default;
    @include standardTransition;

  }

  &__additional.close {
    width: auto;
    border: 1px solid $greyLight;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 0;
    @include standardTransition;

    .cv__add-button {
      pointer-events: none;
    }

    h4 {
      @include standardTransition;
    }

    &:hover {
      @include standardTransition;
      border-color: $radar;

      h4 {
        @include standardTransition;
        color: $radar !important;
      }

      svg {
        @include standardTransition;

        stroke: $radar;
      }
    }

    .ant-row {
      flex-direction: row-reverse;
    }

  }

  &__additional-list.close {
    width: auto;
  }


  &__additional-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__additional-item {
    border: 1px solid $greyLight;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  &__additional-button {
    svg {
      @include standardTransition;

    }

    &:hover {
      svg {
        @include standardTransition;
        stroke: $radar;
      }
    }

    &.delete {
      &:hover {
        svg {
          @include standardTransition;
          stroke: $red;
        }
      }
    }
  }
}

body h5.cv__additional-list--address {
  color: $greyLight !important;
}