* {
  font-family: 'Ubuntu', sans-serif;
  font-size: 0.875rem;
  line-height: 1.313rem;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: $radar;
    border-radius: 10px;
  }
}

body {
  color: $dark;

}