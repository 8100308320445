.user {
  padding: 100px 0;

  &__background {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);

    .settings__uploader {
      transform: translate(0, 0) !important;

    }

    .settings__image,
    img,
    .settings__uploader,
    .settings__image-overlay,
    .settings__image-upload {
      box-shadow: none !important;
      stroke: none !important;
      border: none !important;
      width: 100%;
      height: 400px;
      border-radius: 20px !important;
    }
  }

  .settings__uploader {
    transform: translate(100px, -80px);
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__top-avatar {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__top-info {
    transform: translate(100px, -40px);
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__top-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  &__top-mail {
    font-size: 18px;
    line-height: 27px;
  }

  &__top-social {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__top-edit{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  &__top-add{
    display: flex;
    align-items: center;
    gap: 5px;
  }
}