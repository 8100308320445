$radar: #0a5cc2;
$black: #000000;
$dark: #1a1a1a;
$white: #ffffff;
$greyLight: #adadad;
$greyDark: #716e6e;
$error: #af002a;
$facebookColor: #415e9b;
$googleColor: #eb483b;
$darkBackground: #1e1e1e;
$darkElement: #232527;
$red: #bb3b3e;
$green: #24a052;
$orange: #f07427;

@mixin standardTransition {
  transition: all 0.3s linear;
}

@mixin boxShadow {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

@mixin box {
  @include boxShadow;
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
}

body {
  @include standardTransition;

  svg {
    fill: transparent;
    stroke: $dark;
  }
}

svg {
  width: 25px;
  height: 25px;
}

.blue {
  color: $radar;
  font-weight: 400;
}

.container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  &__small {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
}

h1 {
  font-size: 2.25rem;
  line-height: 3.375rem;
  font-weight: 400;
}

h2 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 400;
}

h3 {
  font-size: 1.75rem;
  line-height: 2.625rem;
  font-weight: 400;
}

h4 {
  font-size: 1.5rem;
  line-height: 2.255rem;
  font-weight: 400;
}

h5 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 400;
}

p,
h6 {
  font-size: 1.125rem;
  line-height: 1.688rem;
  font-weight: 400;
}

.small {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
}

a {
  @include standardTransition;
}

a:hover {
  @include standardTransition;
  color: $radar;
}

.ant-btn.ant-btn-default {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  padding: 0 30px;
  height: 45px;
  border: 2px solid $radar;
  background-color: transparent;
  @include standardTransition;

  &:hover {
    @include standardTransition;
    opacity: 0.6;
    border-color: $radar;

    span {
      color: $radar;
    }
  }

  svg {
    @include standardTransition;
    stroke: $radar;
  }

  span {
    @include standardTransition;
    color: $radar;
    font-size: 1.125rem;
    line-height: 1.688rem;
  }
}

.ant-btn.ant-btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  padding: 0px 30px;
  height: 45px;
  border: 2px solid transparent;
  background-color: $radar;
  @include standardTransition;
  gap: 10px;

  svg {
    @include standardTransition;
    stroke: $white;
  }

  &:hover {
    @include standardTransition;
    background-color: transparent;
    border-color: $radar;

    svg {
      @include standardTransition;
      stroke: $radar;
    }

    span {
      color: $radar;
    }
  }

  span {
    @include standardTransition;
    color: $white;
    font-size: 1.125rem;
    line-height: 1.688rem;
  }
}

.ant-checkbox-wrapper {
  //transform: translateY(40px);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $radar;
  border: 1px solid $radar !important;
}

.ant-checkbox-inner {
  &::after {
    border: none !important;
    background-image: url(../assets/icons/check.svg);
    width: 17px !important;
    height: 19px !important;
    background-repeat: no-repeat;
    top: -6px !important;
    left: -4px !important;
    transform: rotate(0) !important;
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    border: 1px solid $greyLight;
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $greyDark !important;
    }
  }
}

.ant-form-item-control-input textarea,
.ant-select,
.ant-picker,
span.ant-input-affix-wrapper,
input.ant-input {
  border-radius: 999px;
  font-family: 'Ubuntu', sans-serif;
  font-size: 0.875rem;
  line-height: 1.313rem;
  border: 1px solid $greyLight;
  padding: 0 10px;
  height: 35px;
  @include standardTransition;

  svg {
    @include standardTransition;
  }

  &:hover {
    border-color: $greyDark !important;
  }

  &:hover:focus,
  &:focus {
    border-color: $radar !important;
  }
}

.ant-form-item-control-input textarea {
  height: 100px;
  border-radius: 20px;
  padding: 10px;
}

.ant-select-open,
.ant-picker.ant-picker-focused,
span.ant-input-affix-wrapper-focused {
  border-color: $radar !important;

  &:hover {
    border-color: $radar !important;
  }

  svg {
    stroke: $radar;
    @include standardTransition;
  }
}

.button__icon {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;

  &:hover {
    svg {
      @include standardTransition;
      stroke: $radar;
    }
  }

  svg {
    @include standardTransition;
    width: 25px;
    height: 25px;
    stroke: $white;
  }
}

.ant-form-item-control-input {
  .ant-select-selection-item {
    position: relative;
    top: 1px;
    font-size: 0.875rem;
    line-height: 1.313rem;
    font-weight: 400;
  }

  .ant-select-selector {
    padding: 0 !important;
  }
}

.button__social {
  display: flex;
  align-items: center;
  gap: 20px;
}

.button__social-link {
  position: relative;
  width: 35px;
  height: 35px;
  background-color: $radar;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  @include standardTransition;
  border: 2px solid transparent;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);

  svg {
    @include standardTransition;
    width: 25px;
    height: 25px;
    stroke: $white;
  }

  &:hover {
    @include standardTransition;
    border-color: $radar;
    background-color: transparent;
    box-shadow: 0 0 15px 0 rgba(10, 92, 194, 0.25);

    svg {
      @include standardTransition;
      stroke: $radar;
    }
  }
}

.default__content {
  background-color: $white;
  min-height: calc(100vh - 330px);
  height: 100%;
  width: 100%;
}

.divider {
  display: block;
  width: 100%;
  height: 2px;
  background-color: $radar;
  border-radius: 999px;
}

.ant-input-affix-wrapper {
  svg {
    width: 20px;
    height: 20px;
  }
}

.ant-modal-content {
  .ant-input-affix-wrapper {
    height: 35px !important;

    input {
      height: 30px !important;
      position: relative;
      top: 1px;
    }
  }
}

.ant-modal-close-x,
.ant-modal-close {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  @include standardTransition;
}

.ant-modal-close {
  right: -20px !important;
  top: -20px !important;
}

.ant-modal-close-x {
  background-color: $radar;
  border: 2px solid transparent !important;

  &:hover {
    @include standardTransition;
    border-color: $radar !important;
    background-color: $white;

    svg {
      stroke: $radar;
    }
  }
}

.close__icon {
  width: 35px;
  height: 35px;
  stroke: $white;
  @include standardTransition;
}

.ant-picker-header-view {
  button {
    font-family: 'Ubuntu', sans-serif !important;

    &:hover {
      color: $radar !important;
    }
  }
}

th,
td > div {
  font-family: 'Ubuntu', sans-serif !important;
}

.ant-picker {
  width: 100%;
}

label {
  margin-bottom: 8px;
  display: block;
  font-size: 0.875rem !important;
  line-height: 1.313rem !important;
  height: initial !important;
}

.radar {
  color: $radar;
}

.header__language-select > *,
.footer,
.default__content,
.header {
  @include standardTransition;
}

.ant-switch {
  background-color: $greyDark;

  &:hover {
    background-color: $greyDark !important;
  }

  &.ant-switch-checked {
    background-color: $radar !important;
  }
}

.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 30px);
}

.ant-switch .ant-switch-handle {
  inset-inline-start: calc(100% - 55px);
}

.theme__switcher .ant-switch {
  height: 30px;
  width: 60px;
  background-color: $darkElement;

  .ant-switch-inner-unchecked {
    position: relative;
    left: 8px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ant-switch-inner-checked {
    position: relative;
    left: -6px;
    top: 5px;

    svg {
      width: 20px;
      height: 20px;
      stroke: $white;
    }
  }

  &:hover {
    background-color: $darkElement !important;
  }

  &.ant-switch-checked {
    background-color: $radar !important;
  }

  .ant-switch-handle {
    width: 25px;
    height: 25px;

    &:before {
      border-radius: 50%;
    }
  }
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  @include standardTransition;
  pointer-events: none;
  opacity: 0;
  z-index: 5;

  &.active {
    @include standardTransition;
    opacity: 1;
    pointer-events: initial;
  }
}

.ant-input-data-count {
  margin-bottom: 10px !important;
}

aside.ant-layout-sider {
  min-height: calc(100vh - 320px) !important;
  overflow: initial !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid $radar;
  height: 20px;
  border-radius: 20px;
  color: $radar;
  margin: 20px 0;
  @include standardTransition;

  &:hover {
    @include standardTransition;
    opacity: 0.6;
  }
}

.modal__crop .ant-modal-body {
  max-height: 600px;
  width: 100%;
  overflow-y: auto;
}

.ant-skeleton-image,
.ant-skeleton {
  width: 100% !important;
  height: 100% !important;
}

.file__uploader-drop {
  width: 100%;
}

.ant-menu-submenu.ant-menu-submenu-inline {
  @include standardTransition;
  border-radius: 10px;
}