.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}


.razar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 250px;
  height: 250px;
  background-size: 100% 100%;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2), inset 0 1px 1px 0 rgba(255, 255, 255, 0.2), inset 0 2px 4px 1px rgba(255, 255, 255, 0.1);
}

.pulse {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: #69A6F7;
  -moz-animation: pulsating 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation: pulsating 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.0;
  z-index: 5;
}

.ringbase {
  position: absolute;
  top: -50px;
  left: -50px;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  opacity: 0.0;
  z-index: 10;
}

.ring1 {
  box-shadow: 0 0 2px 1px $radar, inset 0 0 2px 1px $radar;
  -moz-animation: ring 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation: ring 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
}

.ring2 {
  box-shadow: 0 0 1px 0px $radar, inset 0 0 1px 0px $radar;
  -moz-animation: ring 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 0.5s;
  -webkit-animation: ring 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.5s;
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}

@-webkit-keyframes pulsating {
  0% {
    opacity: 0.0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.0;
  }
}

@-moz-keyframes pulsating {
  0% {
    opacity: 0.0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.0;
  }
}

@keyframes pulsating {
  0% {
    opacity: 0.0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.0;
  }
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: scale(0.4, 0.4);
    opacity: 0.0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: scale(1.1, 1.1);
    opacity: 0.0;
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: scale(0.4, 0.4);
    opacity: 0.0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    -moz-transform: scale(1.1, 1.1);
    opacity: 0.0;
  }
}

@keyframes ring {
  0% {
    transform: scale(0.4, 0.4);
    opacity: 0.0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: scale(1.1, 1.1);
    opacity: 0.0;
  }
}


.pointer {
  width: 200px;
  -webkit-animation: circling 2s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: circling 2s linear;
  -moz-animation-iteration-count: infinite;
  animation: circling 2s linear;
  animation-iteration-count: infinite;
  z-index: 20;
}

.pointer div {
  width: 49%;
  border-bottom: 2px solid $radar;
}

.dot {
  opacity: 0;
  border: 3px solid $radar;
  border-radius: 100%;
  position: absolute;
  -webkit-animation: blink 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: blink 2s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: blink 2s ease-out;
  animation-iteration-count: infinite;
  z-index: 25;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: $radar;

  &.pos1 {
    left: 20%;
    top: 50%;
  }

  &.pos2 {
    left: 30%;
    top: 30%;
  }

  &.pos3 {
    left: 45%;
    top: 10%;
    animation-delay: 0.5s !important;

  }

  &.pos4 {
    left: 50%;
    top: 35%;
    width: 8px;
    height: 8px;
    animation-delay: 0.6s !important;

  }

  &.pos5 {
    left: 60%;
    top: 25%;
    animation-delay: 0.7s !important;

  }

  &.pos6 {
    left: 70%;
    top: 50%;
    animation-delay: 1s !important;

  }

  &.pos7 {
    left: 80%;
    top: 60%;
    animation-delay: 1.2s !important;
    width: 12px;
    height: 12px;
  }

  &.pos8 {
    left: 60%;
    top: 80%;
    animation-delay: 1.4s !important;

  }

  &.pos9 {
    left: 40%;
    top: 70%;
    animation-delay: 1.6s !important;
  }
}


@-webkit-keyframes circling {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes circling {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes circling {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
