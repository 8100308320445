.ant-btn.header__navigation-button {
  padding: 0;
  height: initial;


}

.ant-select-item-option-content {
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.ant-select-item.ant-select-item-option-selected {
  color: $radar !important;
  font-weight: 400 !important;
}

.ant-form-item {
  margin-bottom: 15px !important;
}


.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none !important;
}

.ant-menu.ant-menu-root {
  position: relative;
}

.ant-menu-submenu-title {
  padding-left: 10px !important;
  width: 100% !important;
}

.ant-menu.ant-menu-sub {
  position: absolute;
  width: 100%;
  top: 60px;
  right: 0;
  background-color: $white !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10) !important;
  border-radius: 10px !important;
  z-index: 3;
}

.ant-menu-item {
  padding: 0 10px !important;
}

.ant-menu-title-content {
  font-size: 1.25rem;
  line-height: 1.875rem
}

.ant-drawer-content-wrapper,
.ant-drawer-mask,
.ant-drawer {
  z-index: 9 !important;
}

.ant-drawer-header {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ant-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.ant-drawer-title {
  font-weight: 400 !important;
}

.ant-picker-cell-today {
  border-color: $radar;
}

.ant-picker-footer .ant-picker-today-btn {
  color: $radar;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: $radar !important;
}

.anticon.anticon-close-circle svg,
.ant-picker-clear svg {
  fill: $radar;
  width: 18px;
  height: 18px;
}

.ant-select-clear {
  width: 10px !important;
  height: 10px !important;
}

.ant-select .anticon.anticon-close-circle {
  position: relative;
  top: -3px;
  right: 6px;
}


.ant-form-item-control-input .ant-select-arrow svg,
.ant-picker-suffix svg {
  width: 20px;
  height: 20px;
  stroke: $greyLight;

}

.ant-form-item-control-input .ant-select-open {
  .ant-select-arrow svg {
    stroke: $radar !important;

  }
}

.ant-form-item-control-input {
  &:hover {
    .ant-select-arrow svg {
      stroke: $greyDark;

    }
  }
}


.ant-picker {
  cursor: pointer;

  input {
    cursor: pointer;

  }

  &:hover {
    .ant-picker-suffix svg {
      stroke: $greyDark;

    }
  }
}

.ant-picker.ant-picker-focused {
  .ant-picker-suffix svg {
    stroke: $radar;

  }

}

.ant-picker .ant-picker-input > input {
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 400;
}

.ant-select-selection-search,
.ant-select,
.ant-select-selector {
  transition: all .3s linear !important;

}

.ant-drawer-header-title {
  display: none !important;
}

.ant-drawer-extra {
  width: 100%;
}

.ant-notification {
  .anticon.anticon-close-circle.ant-notification-notice-icon.ant-notification-notice-icon-error {
    svg {
      fill: $red !important;

    }
  }

  .anticon.anticon-close-circle.ant-notification-notice-icon.ant-notification-notice-icon-warning {
    svg {
      fill: $orange;

    }
  }

  .anticon.anticon-close-circle.ant-notification-notice-icon.ant-notification-notice-icon-success {
    svg {
      fill: $green;

    }
  }

  .anticon.anticon-close-circle.ant-notification-notice-icon.ant-notification-notice-icon-info {
    svg {
      fill: $green;

    }
  }

  .ant-notification-notice-close,
  .ant-notification-close-x,
  .anticon.anticon-close.ant-notification-close-icon {

    svg {
      fill: $radar;
      @include standardTransition;

    }

    &:hover {
      background-color: $white !important;

      svg {
        @include standardTransition;

        opacity: 0.6;
      }
    }

  }
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  min-width: 200px;
  right: -50px;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: $black !important;
}

body .ant-menu-item.ant-menu-item-selected {
  pointer-events: none;

  .ant-menu-title-content {
    color: $radar !important;
  }

  svg {
    stroke: $radar;
  }
}

.ant-pagination li {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-layout-content {
  @include standardTransition;
}

.ant-select-item-option-selected {
  pointer-events: none;
}

.ant-menu-submenu-title,
.ant-menu-item {
  display: flex !important;
  align-items: center;
}

.ant-layout-sider {
  .ant-menu.ant-menu-sub {
    position: initial !important;
    background: $white !important;
    box-shadow: none !important;
  }
}

.ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.ant-modal-close-x {
  svg {
    stroke: $white;
    @include standardTransition;
  }
}

.ant-table-wrapper {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
  border-radius: 10px;

}

.ant-pagination .ant-select-selector {
  background: transparent !important;
}

.ant-pagination .ant-pagination-item-active {
  border: 1px solid $radar;

  a {
    color: $radar !important;
  }
}

.ant-pagination {
  padding-left: 20px !important;
  padding-right: 20px !important;

  .ant-select {
    display: flex !important;
    align-items: center;

    .ant-select-arrow svg {
      stroke: none !important;
      fill: $dark !important;
      width: 10px;
      height: 10px;
    }

    &.ant-select-open svg {
      fill: $greyLight !important;
    }
  }
}

.ant-empty-image {


  svg {
    width: 300px;
    height: 300px;
  }
}