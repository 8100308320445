@media(max-width: 1300px) {
  body {
    .header {
      .theme__switcher,
      &__right,
      &__center {
        display: none;
      }

      &__navigation-button {
        width: 100%;
      }

      &__menu {
        display: block;
      }

      &__search {
        z-index: 2;
      }
    }
  }

  .ant-menu-submenu-title {
    margin-inline: 0 !important;
    padding: 0 !important;
    margin-right: 0 !important;
  }
  .overlay {
    z-index: 1;
  }
}

@media(max-width: 990px) {
  .cv__avatar-col {
    display: flex;
    justify-content: center;
  }
}

@media(max-width: 800px) {
  body {
    h1 {
      font-size: 1.75rem;
      line-height: 2.625rem;
      font-weight: 400;

    }

    h2 {
      font-size: 1.5rem;
      line-height: 2.25rem;
      font-weight: 400;

    }

    h3 {
      font-size: 1.375rem;
      line-height: 2.063rem;
      font-weight: 400;

    }

    h4 {
      font-size: 1.25rem;
      line-height: 1.875rem;
      font-weight: 400;

    }

    h5 {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;

    }

    p,
    h6 {
      font-size: 0.875rem;
      line-height: 1.313rem;
      font-weight: 400;

    }

    .small {
      font-size: 0.75rem;
      line-height: 1.125rem;
      font-weight: 400;

    }

    .home {
      padding: 50px 0;

      &__slogan-text {
        font-size: 2.5rem;

        .blue {
          font-size: 2.5rem;

        }
      }

      &__slogan-content {
        flex-direction: column-reverse;

        .ant-btn {
          width: 100%;
        }
      }

      &__slogan-left {
        width: 100%;
      }

      &__slogan-right {
        width: 100%;
      }
    }

    .footer {
      height: 100%;

      &__info {
        align-items: center;
        width: 100%;

        .ant-btn {
          width: 100%;
        }
      }

      &__content {
        height: 100%;
      }

      &__content-info {
        flex-direction: column;
        gap: 20px;
      }

      &__copyright-info {
        margin-top: 20px;
        margin-bottom: 10px;
        flex-direction: column-reverse;
        gap: 20px;
      }
    }

    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

@media(max-width: 500px) {
  body {
    .footer {
      &__links {
        width: 100%;
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }

      &__links-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__links-list {
        align-items: center;
      }
    }
  }

}