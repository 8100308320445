.admin {
  &__magazine {
    margin: 40px 0;
  }
}

.settings {
  &__tab {
    margin-left: 200px;
  }
}